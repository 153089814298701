<template>
	<div class="user-list-box">
		<div class="list-box">
			<vue-scroll :ops="ops">
				<div class="cell-ul header-ul">
					<div class="left-box cell">
						<p class="width-s">序号</p>
						<p>企业&nbsp;/&nbsp;项目</p>
					</div>
					<div class="right-box cell">
						<p class="width-l cell-box">账户</p>
						<p class="width-l cell-box">密码</p>
						<p class="width-l cell-box">负责人</p>
						<p class="width-l cell-box">角色</p>
						<p class="width-xs cell-box editor" v-show="judgment('authorityList', 271, this)">编辑</p>
					</div>
				</div>
				<!--  -->
				<div class="list">
					<vue-scroll>
						<el-collapse v-model="activeNames" @change="handleChange">
							<el-collapse-item v-for="(item, index) in listData" :name="index" :key="index" class="b-color">
								<template slot="title">
									{{ index + 1 }}
									<span class="collapse-item-name">{{ item.name }}</span>
								</template>
								<div class="content-box">
									<div class="cell-ul content-ul" v-for="(i, ind) in item.list" :key="ind">
										<div class="left-box cell">
											<p class="width-s text-overflow">{{ index + 1 + '.' + (ind + 1) }}</p>
											<div class="width-xl text-overflow cp-color">
												<el-tooltip placement="right" effect="light" v-if="i.array.length != 0">
													<div slot="content">
														<p v-for="(val, n) in i.array" :key="n">
															<span v-if="val.hasOwnProperty('companyName')">{{ val.companyName }}</span>
															<span v-else>{{ val.projectName }}</span>
														</p>
													</div>
													<p>
														<span class="company" v-if="i.array[0].hasOwnProperty('companyName')">{{ i.array[0].companyName | dataDetect }}</span>
														<span class="project" v-else>{{ i.array[0].projectName | dataDetect }}</span>
													</p>
												</el-tooltip>
												<p v-else>
													<span class="company">-</span>
													<!-- &nbsp;/&nbsp;
													<span class="project">-</span> -->
												</p>
											</div>
										</div>
										<div class="right-box cell">
											<p class="width-l cell-box text-overflow txt-color">{{ i.account | dataDetect }}</p>
											<div class="width-l cell-box text-overflow find-pw">
												<!-- <el-input type="password" id="pw" readonly v-model="i.pw"></el-input> -->
												<span class="pwd-show-n">·······</span>
												<p class="pwd-show text-overflow">{{ i.pw }}</p>
												<span class="icon iconfont icon-chakanmima1 pointer" @click.stop="toViewPw"></span>
											</div>
											<p class="width-l cell-box text-overflow">{{ i.person | dataDetect }}</p>
											<p class="width-l cell-box text-overflow">{{ i.roleName | dataDetect }}</p>
											<p class="width-xs cell-box editor" v-show="judgment('authorityList', 271, that)">
												<i class="el-icon-edit edit pointer" @click.stop="listEdit(i)"></i>
												<i class="el-icon-delete delete pointer" @click.stop="listDelete(i.id, i.account)"></i>
											</p>
										</div>
									</div>
								</div>
							</el-collapse-item>
						</el-collapse>
					</vue-scroll>
				</div>
			</vue-scroll>
		</div>
	</div>
</template>

<script>
export default {
	name: '',
	props: {
		dataList: {
			type: Array,
			default: () => {
				return [];
			}
		},
		//权限数组中id对应的功能 name:
		authorityList: {
			type: Array,
			default: () => {
				return [];
			}
		} //权限数组
	},
	data() {
		return {
			search: '', // 搜索条件
			activeNames: [],
			roleList: [], //弹窗角色列表
			enterpriseList: [], //弹窗企业列表
			projectList: [], //弹窗项目列表
			ops: {
				bar: {
					onlyShowBarOnScroll: false, //不止只在滚动时显示 bar。
					keepShow: true //滚动条一直显示
				}
			}, //滚动条设置
			that: null,
			regular: /^[\d\w]|[\d]|[\w]$/ // 密码验证
		};
	},
	created() {
		this.that = this;
	},
	mounted() {},
	computed: {
		listData() {
			var array = [];
			this.dataList.forEach((i, index) => {
				let arr = [];
				i.list.forEach((item, ind) => {
					if (this.search) {
						let s = this.search.toLowerCase();
						let acc = item.account.toLowerCase();

						if (acc.indexOf(s) != -1) {
							arr = this.screening(arr, item);
						}
						item.array.forEach(v => {
							if (v.hasOwnProperty('companyName')) {
								if (v.companyName.indexOf(this.search) != -1) {
									arr = this.screening(arr, item);
								}
							}
							if (v.hasOwnProperty('projectName')) {
								if (v.projectName.indexOf(this.search) != -1) {
									arr = this.screening(arr, item);
								}
							}
						});
					} else {
						arr.push(item);
					}
				});
				if (arr.length != 0) {
					array.push({
						name: i.name,
						list: arr
					});
				}
			});
			return array;
		}
	},
	methods: {
		// 搜索
		searchData(data) {
			this.search = data;
		},
		// 搜索时数据压入筛选
		screening(arr, data) {
			let len = arr.length;
			var state = false;
			for (let i = 0; i < len; i++) {
				let val = arr[i];
				if (val.id == data.id) {
					state = true;
					break;
				}
			}
			if (!state) {
				arr.push(data);
			}
			return arr;
		},
		// 折叠面板变化函数
		handleChange(val) {
			// console.log(val);
			this.activeNames = val;
			// console.log(this.activeNames);
		},
		// 列表编辑
		listEdit(data) {
			// console.log(data);
			this.$parent.User.account = data.account;
			this.$parent.User.password = data.pw;
			this.$parent.User.person = data.person;
			this.$parent.User.roleId = data.roleName;
			this.$parent.User.dc1 = data.dc1;
			this.$parent.User.companyId = [];
			this.$parent.User.projectId = [];
			data.array.forEach(i => {
				// console.log(this.$parent.User.companyId.indexOf(i.cid))
				if (this.$parent.User.companyId.indexOf(i.cid) == -1) {
					this.$parent.User.companyId.push(i.cid);
				}

				if (i.hasOwnProperty('pjid')) {
					if (this.$parent.User.projectId.indexOf(i.pjid) == -1) {
						this.$parent.User.projectId.push(i.pjid);
					}
				}
			});
			this.$parent.User.id = data.id;
			this.$parent.User.ut = [];
			if (data.ut == 1) {
				this.$parent.User.ut.push(1);
			} else if (data.ut == 2) {
				this.$parent.User.ut.push(2);
			} else if (data.ut == 3) {
				this.$parent.User.ut.push(1);
				this.$parent.User.ut.push(2);
			} else if (data.ut == 4) {
				this.$parent.User.ut.push(4);
			} else if (data.ut == 5) {
				this.$parent.User.ut.push(1);
				this.$parent.User.ut.push(4);
			} else if (data.ut == 6) {
				this.$parent.User.ut.push(2);
				this.$parent.User.ut.push(4);
			} else if (data.ut == 7) {
				this.$parent.User.ut.push(1);
				this.$parent.User.ut.push(2);
				this.$parent.User.ut.push(4);
			} else if (data.ut == 8) {
				this.$parent.User.ut.push(8);
			} else if (data.ut == 10) {
				this.$parent.User.ut.push(2);
				this.$parent.User.ut.push(8);
			} else if (data.ut == 11) {
				this.$parent.User.ut.push(1);
				this.$parent.User.ut.push(2);
				this.$parent.User.ut.push(8);
			} else if (data.ut == 12) {
				this.$parent.User.ut.push(4);
				this.$parent.User.ut.push(8);
			} else if (data.ut == 14) {
				this.$parent.User.ut.push(2);
				this.$parent.User.ut.push(4);
				this.$parent.User.ut.push(8);
			} else if (data.ut == 15) {
				this.$parent.User.ut.push(1);
				this.$parent.User.ut.push(2);
				this.$parent.User.ut.push(4);
				this.$parent.User.ut.push(8);
			}

			if (data.al == 0) {
				this.$parent.User.typeOne = 0;
				this.$parent.User.typeTwo = 0;
				this.$parent.User.typeThree = 0;
			} else if (data.al == 1) {
				this.$parent.User.typeOne = 1;
				this.$parent.User.typeTwo = 0;
				this.$parent.User.typeThree = 0;
			} else if (data.al == 2) {
				this.$parent.User.typeOne = 0;
				this.$parent.User.typeTwo = 2;
				this.$parent.User.typeThree = 0;
			} else if (data.al == 3) {
				this.$parent.User.typeOne = 1;
				this.$parent.User.typeTwo = 2;
				this.$parent.User.typeThree = 0;
			} else if (data.al == 4) {
				this.$parent.User.typeOne = 0;
				this.$parent.User.typeTwo = 0;
				this.$parent.User.typeThree = 4;
			} else if (data.al == 5) {
				this.$parent.User.typeOne = 1;
				this.$parent.User.typeTwo = 0;
				this.$parent.User.typeThree = 4;
			} else if (data.al == 6) {
				this.$parent.User.typeOne = 0;
				this.$parent.User.typeTwo = 2;
				this.$parent.User.typeThree = 4;
			} else if (data.al == 7) {
				this.$parent.User.typeOne = 1;
				this.$parent.User.typeTwo = 2;
				this.$parent.User.typeThree = 4;
			}
			this.$parent.open('add-user', true);
			var len = data.array.length;
			if (len == 1) {
				setTimeout(() => {
					this.$parent.assignment(data.array[0].cid, false);
				}, 300);
			}
		},
		// 修改用户
		userEdit() {
			var data = {};
			var type = 0;
			// if (this.$parent.User.companyId.toString() == '') {
			// 	this.$message({
			// 		showClose: true,
			// 		message: '请选择公司',
			// 		type: 'error'
			// 	});
			// 	return;
			// } else if (this.$parent.User.projectId.toString() == '') {
			// 	if (this.$parent.User.companyId != 0) {
			// 		this.$message({
			// 			showClose: true,
			// 			message: '请选择项目',
			// 			type: 'error'
			// 		});
			// 		return;
			// 	}
			// } else
			if (!this.regular.test(this.$parent.User.password)) {
				this.$message({
					showClose: true,
					message: '请输入正确的密码，即只包含数组和字母',
					type: 'error'
				});
				return;
			}
			for (let key in this.$parent.User) {
				if (key.indexOf('type') == -1) {
					if (key != 'companyId' && key != 'projectId') {
						data[key] = this.$parent.User[key];
					} else {
						data[key] = this.$parent.User[key].toString();
					}
				} else {
					if (key != 'type' && key != 'ut') {
						type = type + this.$parent.User[key];
					}
				}
			}
			data.type = type;
			if (typeof data.roleId != 'number') {
				data.roleId = '';
			}
			data.ut = 0;
			this.$parent.User.ut.forEach(v => {
				data.ut = data.ut + v;
			});
			this.axios.post('web2/user/wodn/mfu', data).then(res => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '用户修改成功',
						type: 'success'
					});
					this.$parent.close('add-user');
					this.$parent.getList();
				}
			});
		},
		// 列表删除
		listDelete(val, name) {
			this.$confirm('此操作将删除<strong>' + name + '</strong>，是否继续?', '警告', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
				center: true,
				dangerouslyUseHTMLString: true
			})
				.then(() => {
					this.axios.post('web2/user/wodn/dt', { id: val }).then(res => {
						if (res.status) {
							this.$message({
								showClose: true,
								type: 'success',
								message: '用户删除成功!'
							});
							this.$parent.getList();
						}
					});
				})
				.catch(() => {});
		},
		// 密码查看
		toViewPw(e) {
			const currentTarget = $(e.currentTarget);
			const obj1 = currentTarget.prevAll('.pwd-show-n');
			const obj2 = currentTarget.prevAll('.pwd-show');
			if (obj1.is(':hidden')) {
				obj1.show();
				obj2.hide();
				currentTarget.css({
					color: '#000000'
				});
				currentTarget.removeClass('icon-chakanmima');
				currentTarget.addClass('icon-chakanmima1');
			} else {
				obj1.hide();
				obj2.show();
				currentTarget.css({
					color: '#66b1ff'
				});
				currentTarget.removeClass('icon-chakanmima1');
				currentTarget.addClass('icon-chakanmima');
			}
		},
		// 折叠面板打开参数赋值
		setCollapse(val = []) {
			this.activeNames = [];
			val.forEach((i, index) => {
				this.activeNames.push(index);
			});
		}
	},
	components: {},
	beforeDestroy() {},
	watch: {
		listData(val, old) {
			this.setCollapse(val);
		}
	}
};
</script>

<style scoped lang="scss">
.user-list-box {
	width: 100%;
	height: calc(100% - 110px);

	.list-box {
		width: 100%;
		height: 100%;

		// vue-scroll插件内部滚动区域样式更改。避免多个vue-scroll套用时高度不能按照外部盒子高度进行滚动
		::v-deep {
			.__view {
				height: 100%;
			}

			.el-collapse-item__header {
				padding-left: 15px;
			}

			.el-collapse-item__content {
				padding-left: 28px;
				padding-bottom: 5px;
			}
		}

		.collapse-item-name {
			margin-left: 15px;
		}

		.b-color {
			::v-deep {
				.el-collapse-item__header {
					background-color: #f7f7f8;
				}
			}
		}

		.cell-ul {
			width: 100%;
			height: 40px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			color: #000000;

			.cell {
				display: flex;
				justify-content: space-between;
				align-items: center;

				.width-xl {
					width: 445px;
				}

				.width-l {
					width: 110px;
				}

				.width-s {
					width: 90px;
				}

				.width-xs {
					width: 60px;
				}

				.editor {
					display: flex;
					justify-content: space-around;
					align-items: center;

					i {
						font-size: 20px;
					}
				}

				.company {
					font-size: 16px;
				}

				.project {
					font-size: 15px;
				}

				.cp-color {
					color: #4f4faf;
				}

				.txt-color {
					color: #00a000;
				}

				.edit {
					color: #409eff;
				}

				.delete {
					color: #e56c6c;
				}

				.find-pw {
					display: flex;
					justify-content: space-between;
					align-items: center;

					::v-deep {
						.el-input__inner {
							width: 90px;
							border: none;
							background: none;
							padding: 0;
						}
					}
				}

				.pwd-show-n {
					font-size: 18px;
					font-weight: 600;
					color: #000000;
				}

				.pwd-show {
					width: calc(100% - 20px);
					display: none;
					color: #909399;
				}
			}

			.left-box {
				width: 40%;
				// min-width: 737px;
			}

			.right-box {
				justify-content: flex-start;

				.cell-box {
					margin-right: 15px;
				}
			}
		}

		.cell-ul:nth-child(even) {
			background-color: #ecf5ff;
		}

		.header-ul {
			height: 45px;
			line-height: 45px;
			background-color: #ebeef5;
			font-size: 15px;
			font-weight: 600;
			color: #4c4e51;
			letter-spacing: 1px;
			border-bottom: 2px solid #ffffff;

			.cell {
				// font-size: 17px;
			}

			.left-box {
				p:first-child {
					margin-left: 25px;
				}

				p:nth-child(2) {
					width: 431px;
					// padding-left: 11px;
				}
			}

			.right-box {
				margin-left: 10px;
			}
		}

		// tree树形结构内容
		.list {
			width: 100%;
			height: calc(100% - 55px);

			::v-deep {
				.el-icon-caret-right {
					font-size: 16px;
				}

				.el-tree-node__content {
					padding: 3px 0;
				}

				.el-tree-node {
					// padding: 5px 0;
					border-bottom: thin solid #dcdfe6;
				}

				.el-tree-node__children {
					.el-tree-node {
						border-bottom: none;
						background-color: #ffffff !important;
					}
				}

				.el-tree-node:nth-child(odd) {
					background-color: #ffffff;
				}

				.el-tree-node:nth-child(even) {
					background-color: #f2f2f2;
				}
			}

			.custom-tree-node {
				width: 100%;
			}

			.content-ul {
				font-size: 14px;
				color: #606266;
			}
		}
	}
}
</style>
