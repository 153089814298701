import data from "./userData.js";
import list from "./components/list.vue";

export default {
	name: "user",
	data,
	created() {
		this.getAuthorityList();
		this.getList();
	},
	mounted() {},
	computed: {
		userTypeOption() {
			var arr = [];
			var typeAuthority = this.typeAuthority;
			if (typeAuthority == 1) {
				arr = [1];
			} else if (typeAuthority == 2) {
				arr = [2];
			} else if (typeAuthority == 3) {
				arr = [1, 2];
			} else if (typeAuthority == 4) {
				arr = [4];
			} else if (typeAuthority == 5) {
				arr = [1, 4];
			} else if (typeAuthority == 6) {
				arr = [2, 4];
			} else if (typeAuthority == 7) {
				arr = [1, 2, 4];
			} else if (typeAuthority == 8) {
				arr = [8];
			} else if (typeAuthority == 10) {
				arr = [2, 8];
			} else if (typeAuthority == 11) {
				arr = [1, 2, 8];
			} else if (typeAuthority == 12) {
				arr = [4, 8];
			} else if (typeAuthority == 14) {
				arr = [2, 4, 8];
			} else if (typeAuthority == 15) {
				arr = [1, 2, 4, 8];
			}
			return this.userTypesList.filter((i) => {
				if (arr.indexOf(i.value) != -1) {
					return i
				}
			})
		}
	},
	methods: {
		// 获取权限列表 car
		getAuthorityList() {
			this.axios.get('web2/user/odnr/gath').then((res) => {
				this.authorityList = [];
				if (res.status) {
					this.authorityList = res.data;
					if (res.data.indexOf(271) != -1) {
						this.getParameter();
					}
				}
			})
		},
		// 获取用户列表数据
		getList() {
			this.axios.get('web2/user/odnr/glist').then((res) => {
				this.listData = [];
				for (let key in this.info) {
					this.info[key] = '';
				}
				if (res.status) {
					this.loginAuthority = res.data.isl;
					this.typeAuthority = res.data.ut;
					for (let key in res.data) {
						if (key == 'children') {
							this.listData = res.data[key];
						} else {
							this.info[key] = res.data[key];
							this.backup[key] = res.data[key];
						}
					}
				}
			})
		},
		// 获取角色、企业、项目列表数据
		getParameter() {
			this.axios.get('web2/user/wodn/gcpjr').then((res) => {
				this.roleList = [];
				this.enterpriseList = [];
				this.projectList = [];
				if (res.status) {
					this.roleList = res.data.role;
					this.enterpriseList = res.data.company;
					if (this.enterpriseList.length == 1) {
						this.User.companyId = [this.enterpriseList[0].id];
						this.assignment(this.enterpriseList[0].id, false)
					}
				}
			})
		},
		// 项目下拉框数据赋值
		assignment(val, status = false) {
			// console.log(val)
			var len = this.enterpriseList.length;
			for (let i = 0; i < len; i++) {
				let v = this.enterpriseList[i];
				if (v.id == val) {
					this.projectList = v.project;
					break;
				}
			}
			if (status) {
				// // console.log('清除')
				this.User.projectId = [];
			}
		},
		// 打开企业项目选择框
		openTransfer(val) {
			// // console.log(this.User.companyId)
			if (val == "企业") {
				// if (!this.status) {
				// 	if (this.enterpriseList.length == 1) {
				// 		this.User.companyId = [this.enterpriseList[0].id];
				// 		this.assignment(this.enterpriseList[0].id, false)
				// 	}
				// }
			} else if (val == '项目') {
				if (this.User.companyId.length == 0) {
					this.$message({
						message: '请先选择企业！',
						type: 'warning'
					});
					return
				} else if (this.User.companyId.length > 1) {
					this.$message({
						message: '选择多个企业时不可选择项目！',
						type: 'warning'
					});
					return
				}
				if (this.User.companyId.length == 1) {
					this.assignment(this.User.companyId[0]);
				}

			}
			this.selectN = val;
		},
		// 企业选择
		leftESelect(data, val) {
			data.forEach((i) => {
				this.User.companyId.push(i)
			})
			if (this.User.companyId.length == 1) {
				this.assignment(this.User.companyId[0], true);
			} else {
				this.User.projectId = [];
			}
			// // console.log(this.User.projectId)
		},
		// 企业取消选择
		rightESelect(data, val) {
			// // console.log(data)
			data.forEach((i) => {
				let index = this.User.companyId.indexOf(i);
				if (index != -1) {
					this.User.companyId.splice(index, 1);
				}
			})
			this.User.projectId = [];
		},
		// 项目选择
		leftPSelect(data, val) {
			// // console.log(data)
			data.forEach((i) => {
				this.User.projectId.push(i)
			})
		},
		// 项目取消选择
		rightPSelect(data, val) {
			// console.log(data)
			data.forEach((i) => {
				let index = this.User.projectId.indexOf(i);
				if (index != -1) {
					this.User.projectId.splice(index, 1);
				}
			})
		},
		// 新增用户
		addUser() {
			if (this.detectTip) {
				return
			} else if (this.User.companyId.toString() == '') {
				this.$message({
					showClose: true,
					message: '请选择公司',
					type: 'error'
				});
				return
			}
			// else if (this.User.projectId.toString() == '') {
			// 	if (this.User.companyId != 0) {
			// 		this.$message({
			// 			showClose: true,
			// 			message: '请选择项目',
			// 			type: 'error'
			// 		});
			// 		return
			// 	}
			// } 
			else if (!this.regular.test(this.User.password)) {
				this.$message({
					showClose: true,
					message: '请输入正确的密码，即只包含数字和字母',
					type: 'error'
				});
				return
			}
			var data = {};
			var type = 0;
			for (let key in this.User) {
				if (key.indexOf('type') == -1) {
					if (key != 'companyId' && key != 'projectId') {
						data[key] = this.User[key];
					} else {
						data[key] = this.User[key].toString();
					}
					data[key] = this.User[key];
				} else {
					if (key != 'type' && key != 'ut') {
						type = type + this.User[key];
					}
				}
			}
			data.ut = 0;
			this.User.ut.forEach((v) => {
				data.ut = data.ut + v;
			})
			data.type = type;
			this.axios.post('web2/user/wodn/adu', data).then(res => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '用户新增成功',
						type: 'success'
					});
					this.close('add-user');
					this.getList();
				}
			})
		},
		// 检测新增用户账户是否可用
		detect(data) {
			if (data == '') {
				return;
			}
			this.axios.post('web2/user/wodn/car', {
				account: data
			}).then(res => {
				if (res.status) {
					this.detectTip = !res.data;
				}
			})
		},
		// 顶部编辑用户
		userEdit() {
			var data = {
				person: this.info.person,
				// password: this.info.pw,
			}
			this.axios.post('web2/user/odnr/mmn', data).then((res) => {
				if (res.status) {
					this.$message({
						showClose: true,
						message: '用户信息修改成功',
						type: 'success'
					});
					this.$store.state.userName = data.person;
					localStorage.setItem('userName', data.person);
					var info = JSON.parse(localStorage.getItem('data'));
					info.name = data.person;
					localStorage.setItem('data', JSON.stringify(info));
					this.editMode = false;
					$("#pwd").attr('type', 'password');
				}
			})
		},
		// 搜索数据
		searcData() {
			this.$refs.list.searchData(this.search);
		},
		// 用户信息编辑
		Edit(data, val = "") {
			if (val != "") {
				this.editMode = true;
				// $("#pwd").attr('type', 'text');
			} else {

			}
		},
		// 密码查看
		toView(e) {
			// const currentTarget = $(e.currentTarget);
			// const obj = currentTarget.prev().find('input');
			// var type = obj.attr('type');
			// if (type == 'text') {
			// 	obj.attr('type', 'password');
			// 	currentTarget.removeClass('icon-chakanmima');
			// 	currentTarget.addClass('icon-chakanmima1');
			// 	currentTarget.css({
			// 		color: '#000000'
			// 	})
			// } else {
			// 	obj.attr('type', 'text');
			// 	currentTarget.removeClass('icon-chakanmima1');
			// 	currentTarget.addClass('icon-chakanmima');
			// 	currentTarget.css({
			// 		color: '#66b1ff'
			// 	})
			// }
			const currentTarget = $(e.currentTarget);
			const obj1 = currentTarget.prevAll('.pwd-show-n');
			const obj2 = currentTarget.prevAll('.pwd-show');
			if (obj1.is(':hidden')) {
				obj1.show();
				obj2.hide();
				currentTarget.css({
					color: '#000000'
				});
				currentTarget.removeClass('icon-chakanmima');
				currentTarget.addClass('icon-chakanmima1');
			} else {
				obj1.hide();
				obj2.show();
				currentTarget.css({
					color: '#66b1ff'
				});
				currentTarget.removeClass('icon-chakanmima1');
				currentTarget.addClass('icon-chakanmima');
			}
		},
		// 当前用户取消编辑
		cancel() {
			this.editMode = false;
			this.info = this.backup;
		},
		// 打开弹窗
		open(val, status) {
			$('.' + val).show();
			this.status = status;
			if (val == 'add-user') {
				if (!this.status) {
					if (this.enterpriseList.length == 1) {
						this.User.companyId = [this.enterpriseList[0].id];
						this.assignment(this.enterpriseList[0].id, false)
					}
				}
			}
		},
		// 关闭弹窗
		close(val) {
			$('.' + val).hide();
			if (val == 'add-user') {
				this.User = {
					account: '', //账户
					password: '', // 密码
					person: '', // 负责人
					roleId: '', // 角色
					companyId: [], // 企业
					projectId: [], // 项目
					typeOne: 1, // 是否允许登录平台
					typeTwo: 2, // 是否允许登录小程序
					typeThree: 4, // 是否允许登录客户端
					ut: [], // 用户类型
					type: 0, //是否允许登录平台\小程序\客户端
					dc1: 0, //是否显示曲线图
				};
				this.enterprisesC = [];
				this.projectC = [];
				this.projectList = [];
				this.detectTip = false;
				this.selectN = '';
			} else {

			}
		},
		// 页码变化监听
		handleCurrentChange(val) {
			this.pagenumber = val;
			this.currentPage = val;
		},
		// 跳转页码输入
		number() {
			if (this.pagenumber > Math.ceil(this.total / this.pagesize)) {
				this.pagenumber = Math.ceil(this.total / this.pagesize);
			} else if (this.pagenumber < 1 && this.pagenumber.toString() != '') {
				this.pagenumber = 1;
			}
		},
		// 跳转
		jump() {
			if (this.pagenumber == '') {
				this.pagenumber = 1;
			}
			this.currentPage = Number(this.pagenumber);
		},
	},
	components: {
		list
	},
	beforeDestroy() {},
	watch: {}
}
