<template>
	<!-- 用户 -->
	<div class="user">
		<div class="user-main">
			<!-- 搜索 -->
			<div class="search-box">
				<div class="search">
					<el-input placeholder="请输入账户、企业或项目名查询" v-model="search">
						<el-button slot="append" icon="el-icon-search" size="small" @click="searcData"></el-button>
					</el-input>
				</div>
			</div>
			<header>
				<div class="info-box">
					<vue-scroll :ops="ops">
						<div class="scroll-box">
							<div class="cell width-l">
								<span class="name">企业项目：</span>
								<span class="text-overflow content" :title="info.name">{{ info.name | dataDetect }}</span>
							</div>
							<div class="cell width-xs">
								<span class="name">账户：</span>
								<span class="text-overflow content">{{ info.account | dataDetect }}</span>
							</div>
							<div class="cell width-xs">
								<span class="name">密码：</span>
								<div class="pwd-show-box">
									<span class="pwd-show-n">·······</span>
									<span class="pwd-show">{{ info.pw }}</span>
									<span class="icon iconfont icon-chakanmima1 pointer view" @click="toView($event)"></span>
								</div>
								<!-- <el-input type="password" id="pwd" class="border-none" :readonly="true" v-model="info.pw"></el-input> -->
							</div>
							<div class="cell width-xs">
								<span class="name">负责人：</span>
								<el-input
									:class="editMode ? '' : 'border-none'"
									:readonly="!editMode"
									v-model="info.person"
									placeholder="暂无负责人"
									:title="info.person"
								></el-input>
							</div>
							<div class="cell width-xs">
								<span class="name">角色：</span>
								<span class="text-overflow content" :title="info.roleName">{{ info.roleName | dataDetect }}</span>
							</div>
						</div>
					</vue-scroll>
				</div>

				<div class="button-box">
					<el-button v-show="!editMode" size="small" type="primary" icon="el-icon-edit" @click="Edit('', '编辑')"></el-button>
					<el-button v-show="editMode" size="small" type="primary" @click="userEdit">确定</el-button>
					<el-button v-show="editMode" size="small" type="info" @click="cancel">取消</el-button>
					<el-button v-show="judgment('authorityList', 271, this)" size="small" type="primary" @click="open('add-user', false)">新增用户</el-button>
				</div>
			</header>
			<!-- 列表 -->
			<list ref="list" :dataList="listData" :authorityList="authorityList"></list>
		</div>
		<!-- 添加/编辑用户 -->
		<div class="add-user Mask-box">
			<div class="main-box box-center">
				<p class="pop-ups-title">
					<span>{{ status ? '编辑' : '新增用户' }}</span>
					<span class="icon iconfont icon-guanbi close pointer" @click="close('add-user')"></span>
				</p>
				<div class="pop-ups-content">
					<div class="l-r">
						<div class="left-b">
							<div class="cell">
								<span class="title">
									账户
									<i>*</i>
								</span>
								<p class="account" v-show="status">{{ User.account }}</p>
								<el-tooltip v-model="detectTip" class="item" effect="light" manual content="该账户不可用!请重新输入" placement="right">
									<el-input v-show="!status" v-model="User.account" placeholder="请输入账户" @blur="detect(User.account)"></el-input>
								</el-tooltip>
							</div>
							<div class="cell">
								<span class="title">
									密码
									<i>*</i>
								</span>
								<el-input v-model="User.password" placeholder="请输入密码"></el-input>
							</div>
							<div class="cell">
								<span class="title">负责人</span>
								<el-input v-model="User.person" placeholder="请输入负责人"></el-input>
							</div>
							<div class="cell">
								<span class="title">
									角色
									<i>*</i>
								</span>
								<el-select v-model="User.roleId" clearable placeholder="请选择角色">
									<el-option v-for="item in roleList" :key="item.id" :label="item.name" :value="item.id"></el-option>
								</el-select>
							</div>
							<div class="cell" v-if="typeAuthority > 0">
								<span class="title">事件功能</span>
								<el-select multiple popper-class="short-select" v-model="User.ut" placeholder="请选择">
									<el-option v-for="item in userTypeOption" :key="item.value" :label="item.name" :value="item.value"></el-option>
								</el-select>
								<!-- <el-select popper-class="short-select" v-model="User.ut" placeholder="请选择">
									<el-option v-for="item in userTypesList" :key="item.value" :label="item.name" :value="item.value"></el-option>
								</el-select> -->
							</div>
							<div class="cell">
								<span class="title">
									企业
									<i>*</i>
								</span>
								<el-button type="primary" size="small" @click="openTransfer('企业')">选择</el-button>
								<!-- <el-select v-model="User.companyId" clearable filterable placeholder="请选择企业" @change="assignment($event, true)">
									<el-option v-for="item in enterpriseList" :key="item.id" :label="item.name" :value="item.id"></el-option>
								</el-select> -->
							</div>
							<div class="cell">
								<span class="title">
									项目
									<!-- <i>*</i> -->
								</span>
								<el-button type="primary" size="small" @click="openTransfer('项目')">选择</el-button>
								<!-- <el-select :disabled="projectList.length == 0" v-model="User.projectId" clearable filterable placeholder="请选择项目">
									<el-option v-for="item in projectList" :key="item.value" :label="item.name" :value="item.id"></el-option>
								</el-select> -->
							</div>
							<div class="cell" v-if="loginAuthority == 1">
								<span class="title">是否允许登录平台</span>
								<el-switch v-model="User.typeOne" :active-value="1" :inactive-value="0"></el-switch>
							</div>
							<div class="cell" v-if="loginAuthority == 1">
								<span class="title">是否允许登录小程序</span>
								<el-switch v-model="User.typeTwo" :active-value="2" :inactive-value="0"></el-switch>
							</div>
							<div class="cell" v-if="loginAuthority == 1">
								<span class="title">是否允许登录客户端</span>
								<el-switch v-model="User.typeThree" :active-value="4" :inactive-value="0"></el-switch>
							</div>
							<div class="cell" v-if="loginAuthority == 1">
								<span class="title">是否显示曲线图</span>
								<el-switch v-model="User.dc1" :active-value="1" :inactive-value="0"></el-switch>
							</div>
						</div>
						<div class="right-b" v-show="selectN != ''">
							<div class="r-title">
								<span>{{ selectN }}选择</span>
							</div>
							<!-- 企业选择 -->
							<div class="r-content-box" v-show="selectN == '企业'">
								<!-- <div class="left-select">
									<div class="select-b" v-for="(i,index) in enterpriseNCData" :key="index" @click="butSelect(i,'ec')">
										<el-button plain size="small">朴素按钮</el-button>
									</div>
								</div>
								<div class="right-select">
									<div class="select-b" v-for="(i,index) in enterpriseCData" :key="index" @click="butSelect(i,'enc')">
										<el-button plain size="small">朴素按钮</el-button>
									</div>
								</div> -->
								<el-transfer
									filterable
									filter-placeholder="请输入"
									:titles="['未选择企业', '选中企业']"
									v-model="User.companyId"
									:data="enterpriseList"
									:props="{
										key: 'id',
										label: 'name'
									}"
									@left-check-change="leftESelect"
									@right-check-change="rightESelect"
								>
									<template slot-scope="{ option }">
										<el-tooltip :content="option.name" placement="right" effect="light">
											<span>{{ option.name }}</span>
										</el-tooltip>
									</template>
								</el-transfer>
							</div>
							<!-- 项目选择 -->
							<div class="r-content-box" v-show="selectN == '项目'">
								<!-- <div class="left-select">
									<div class="select-b" v-for="(i,index) in projectNCData" :key="index" @click="butSelect(i,'pc')">
										<el-button plain size="small">朴素按钮</el-button>
									</div>
								</div>
								<div class="right-select">
									<div class="select-b" v-for="(i,index) in projectCData" :key="index" @click="butSelect(i,'pnc')">
										<el-button plain size="small">朴素按钮</el-button>
									</div>
								</div> -->
								<el-transfer
									filterable
									filter-placeholder="请输入"
									:titles="['未选择项目', '选中项目']"
									v-model="User.projectId"
									:data="projectList"
									:props="{
										key: 'id',
										label: 'name'
									}"
									@left-check-change="leftPSelect"
									@right-check-change="rightPSelect"
								>
									<template slot-scope="{ option }">
										<el-tooltip :content="option.name" placement="right" effect="light">
											<span>{{ option.name }}</span>
										</el-tooltip>
									</template>
								</el-transfer>
							</div>
						</div>
					</div>
					<div class="button-box">
						<el-button v-show="!status" type="primary" size="mini" @click="addUser" :disabled="User.account == '' || User.password == '' || User.roleId == ''">
							确认
						</el-button>
						<el-button
							v-show="status"
							type="primary"
							size="mini"
							@click="$refs.list.userEdit()"
							:disabled="User.account == '' || User.password == '' || User.roleId == ''"
						>
							修改
						</el-button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import user from './user.js';
export default user;
</script>

<style scoped lang="scss">
@import './user.scss';
</style>
