export default () => {
	return {
		search: "",// 当前搜索字段
		info: {
			name: '', //企业项目名称
			account: '', // 账户
			pw: '', //用户密码
			person: '', // 负责人
			roleName: '', // 角色
			id: '', //用户id
		}, //当前用户信息
		backup: {}, //当前用户信息备份数据
		editMode: false, //头部信息是否处于编辑模式
		listData: [], // 列表数据
		User: {
			account: '', //账户
			password: '', // 密码
			person: '', // 负责人
			roleId: '', // 角色
			companyId: [], // 企业
			projectId: [], // 项目
			typeOne: 1, // 是否允许登录平台
			typeTwo: 2, // 是否允许登录小程序
			typeThree: 4, // 是否允许登录客户端
			ut: [], // 用户类型
			type: 0, //是否允许登录平台\小程序\客户端
			dc1: 0, //是否显示曲线图
		}, //添加/编辑用户
		regular: /^[\d\w]|[\d]|[\w]$/, // 密码验证
		userTypesList: [
			// {
			// 	value: 1,
			// 	name: '普通'
			// },
			{
				value: 2,
				name: '施工'
			},
			{
				value: 4,
				name: '监测'
			},
			
			{
				value: 8,
				name: '审批'
			},
			{
				value: 1,
				name: '监管'
			},
		], // 用户类型选择数据列表
		// userTypesList: [{
		// 		value: 2,
		// 		name: '施工'
		// 	},
		// 	{
		// 		value: 3,
		// 		name: '监测'
		// 	},
		// 	{
		// 		value: 4,
		// 		name: '审批'
		// 	},
		// 	{
		// 		value: 1,
		// 		name: '监管'
		// 	},
		// 	{
		// 		value: 0,
		// 		name: '普通'
		// 	},
		// ], // 用户类型选择数据列表
		selectN: '',// 新增/编辑用户时当前选择填写的是企业/项目
		// enterprisesC: [], // 用户企业选择数据
		// enterprisesNC: [], // 用户企业未选择数据
		// projectC: [], // 用户项目选择数据
		// projectNC: [], // 用户项目未选择数据
		loginAuthority: 0, // 用户是否有控制用户登录的权限
		typeAuthority: 0, // 用户是否拥有选择用户类型的权限
		status: false, //弹窗是否是编辑弹窗
		roleList: [], //弹窗角色列表
		enterpriseList: [], //弹窗企业列表
		projectList: [], //弹窗项目列表
		detectTip: false, //是否提示新增账户不可用
		//权限数组中id对应的功能 name:
		authorityList: [], //权限数组 
		ops: {
			bar: {
				onlyShowBarOnScroll: false, //不止只在滚动时显示 bar。
				keepShow: true, //滚动条一直显示
			}
		}, //滚动条设置
	}
}
